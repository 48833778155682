.transaction-history {
font-family: Arial, sans-serif;
margin: 20px;
}

table {
width: 100%;
border-collapse: collapse;
background-color: #fff;
box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}

th, td {
padding: 15px;
text-align: left;
border-bottom: 1px solid #f0f0f0;
}

th {
background-color: #f7f7f7;
}

td {
color: #333;
}

h1 {
font-size: 24px;
margin-bottom: 20px;
}

  /* Status-specific styles */
.pending .status {
background-color: #fe0d0dab; /* Light red */
}

.cancelled .status {
background-color: #fdcd0a94; /* Light gray */
}

.successful .status {
background-color: #67f867fa; /* Light green */
}