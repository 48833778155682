@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.animate-spin-slow {
  animation-name: spin;
  animation-duration: 10s; /* Adjust this value to control the duration */
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
